<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled label="Абонент" disabled v-model="flat_name" color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-select
                hide-details
                filled
                label="Послуга"
                :items="services"
                v-model="service_id"
                :class="service_id ? '' : 'req-star'"
                color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Назва договору" v-model="contract_name"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="contract_name ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="№ договору" v-model="number"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="number ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_start" req label="Дата договору" :class_="!date_start ? 'req-star' : ''"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_close" label="Дата закінчення"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox class="mt-1 pt-0" v-model="prolonged" hide-details color="grey darken-2" label="Пролонгований"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox class="mt-1 pt-0" v-model="tax_by_pay" hide-details color="grey darken-2" label="ПДВ по оплаті"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createFlatIndicator" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {SELECT_SERVICES} from "@/store/actions/services";
import {CREATE_LEGACY_CONTRACT, DELETE_LEGACY_CONTRACT, UPDATE_LEGACY_CONTRACT} from "@/store/actions/contract";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_contract_legacy_modal_delete'

export default {
  name: "HWP_Modal_FlatLegacyContract",
  mixins: [ModalComponentMixin],
  data() {
    return {
      navigation: false,
      flat_id: this.item.flat_id,
      flat_name: this.item.flat_name,
      date_start: this.item.date_start,
      date_close: this.item.date_close,
      contract_name: this.item.contract_name,
      number: this.item.number || '',
      service_id: this.item.service_id || null,
      prolonged: this.item.prolonged || false,
      tax_by_pay: this.item.tax_by_pay || false,
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelect',
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES
    }),
    flatIndicatorTypeChange(payload) {
      this.indicator_id = payload?.value || null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.flat_id = this.item.flat_id
      this.flat_name = this.item.flat_name
      this.contract_name = this.item.contract_name
      this.date_start = this.item.date_start
      this.date_close = this.item.date_close
      this.number = this.item.number || ''
      this.service_id = this.item.service_id || null
      this.prolonged = this.item.prolonged || false
      this.tax_by_pay = this.item.tax_by_pay || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення договору`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createFlatIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга, Назва, номер та дата договору - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        flat_id: this.flat_id,
        date_start: this.date_start,
        date_close: this.date_close ? this.date_close : null,
        service_id: this.service_id,
        contract_name: this.contract_name,
        number: this.number,
        prolonged: this.prolonged,
        tax_by_pay: this.tax_by_pay || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_LEGACY_CONTRACT, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_LEGACY_CONTRACT, payload)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_id = payload.flat_id
              this.flat_name = payload.flat_name
              this.date = payload.date
              this.date_close = payload.date_close
              this.contract_name = payload.contract_name
              this.number = payload.number || ''
              this.service_id = payload.service_id || null
              this.prolonged = payload.prolonged || false
              this.tax_by_pay = payload.tax_by_pay || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_LEGACY_CONTRACT, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    if (!this.services.length) {
      this.fetchServices()
    }
  }
}
</script>
